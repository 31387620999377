<template>
  <div class="organ_page">
    <div class="marl40">
      <div style="font-size: 20px">《Privacy Policy》</div>
      <div style="margin-top: 20px">
        [Special Note] Please read the Privacy Policy carefully and make sure
        you understand our rules for handling your personal information. If you
        have any questions during the reading process, you can contact our
        customer service for consultation, if you do not agree to any of the
        terms of the agreement, you should immediately stop accessing
        Prefootball. If you do not agree with this Agreement or any of its terms
        and conditions, you should immediately stop using Prefootball.
      </div>
      <div style="margin-top: 20px">Text of Privacy Policy</div>
      <div style="margin-top: 20px">Dear Prefootball users, hello</div>
      <div style="margin-top: 20px">
        Your trust is very important to us, we know the importance of personal
        information to you, we also attach great importance to the protection of
        your privacy and personal information, in order to protect your right to
        know, strengthen the protection of your personal information security,
        we have updated the privacy policy. We may collect and use information
        about you when you use our products and/or services. Through this
        Privacy Policy, we would like to explain to you how we collect, use,
        store, share and transfer this information when you use our
        products/services, and how we provide you with access to, update, delete
        and protect this information.
      </div>
      <div style="margin-top: 20px">
        This policy will help you understand the following:
      </div>
      <div style="margin-top: 20px">
        One、How do we collect and use your Personal information
      </div>
      <div style="margin-top: 20px">
        Two、How do we use cookies and similar technologies
      </div>
      <div style="margin-top: 20px">
        Three、How do we share, transfer and publicly disclose your personal
        information
      </div>
      <div style="margin-top: 20px">
        Four、How do we protect and store your personal information
      </div>
      <div style="margin-top: 20px">
        Five、How do you manage your Personal information
      </div>
      <div style="margin-top: 20px">Six、Information protection of minors</div>
      <div style="margin-top: 20px">
        Seven、Privacy Policy revision and update
      </div>
      <div style="margin-top: 20px">Eight、how to contact us</div>
      <div style="margin-top: 20px">
        Before using our products and/or services, please be sure to carefully
        read and thoroughly understand this policy, especially the terms marked
        in bold/bold underline, and use the relevant products or services after
        confirming full understanding and consent. By using or continuing to use
        our products and/or services after we have updated this Privacy Policy,
        you consent to the contents of this Privacy Policy (including the
        updated version) and consent to our collection, use, storage and sharing
        of your information in accordance with this Privacy Policy.
      </div>
      <div style="margin-top: 20px">
        This Privacy policy applies to Prefootball platforms, including the
        Prefootball website, Prefootball mobile terminal, Prefootball WAP
        terminal, Prefootball applet and other clients.
      </div>
      <div style="margin-top: 20px">
        If you have any questions, comments or suggestions about the contents of
        this Privacy Policy or any questions related to this Privacy Policy, you
        can contact us at any time.
      </div>
      <div style="margin-top: 20px">
        In particular, we draw the attention of minors under 18 years of age
        (especially children under 14 years of age) and their guardians, we have
        identified a special section on the protection of minors (indicated in
        bold), please read it carefully.
      </div>
      <div style="margin-top: 20px; font-size: 20px">
        One、How do we collect and use your Personal information
      </div>
      <div style="margin-top: 20px">
        In this Policy, we will explain to you in turn the scope of personal
        information that may be collected by each feature, the purpose of
        collection, the method of collection, and the possible consequences of
        refusing to provide personal information. Please be aware that if you
        refuse to provide the necessary corresponding information for the
        implementation of each function, you can still use other functions of
        PrefootballApp.
      </div>
      <div style="margin-top: 20px">
        If we want to use your personal information for other purposes not
        specified in this policy, or for other purposes for which we have
        collected information, we will inform you in a reasonable manner and
        obtain your consent again before using it.
      </div>
      <div style="margin-top: 20px">1.Account registration and login</div>
      <div style="margin-top: 20px">
        1.1. When you register and log in, we will collect your email
        information in order to provide you with quick login services;
      </div>

      <div style="margin-top: 20px; font-size: 20px">
        Two、How do we use cookies and similar technologies
      </div>
      <div style="margin-top: 20px; font-size: 20px">（One）Cookie</div>
      <div style="margin-top: 20px">
        Cookie and device information identification similar technologies are
        widely used in the Internet. To make your visit easier and to recommend
        you content that may be of interest to you, we may use relevant
        technologies to send one or more cookies to your device. Cookies
        typically contain an identifier, a site name, and some numbers and
        characters. With the help of cookies, the website can store your
        preferences or goods and services data in the shopping cart. We send
        cookies to you to simplify the process of your repeated login, to store
        your shopping preferences or data about goods and services in your
        shopping cart in order to provide you with shopping preferences, to help
        you optimize your selection and interaction with advertisements, to help
        determine your login status and the security of your account or data.
      </div>
      <div style="margin-top: 20px">
        We do not use cookies for any purpose other than those described in this
        Privacy Policy. You can manage or delete Cookies according to your
        preferences. For details, see AboutCookies.org。You can clear all
        Cookies saved on your device, and most web browsers have a feature that
        blocks cookies. However, if you do so, you will need to change the user
        Settings every time you visit our website or client, and the
        corresponding information you have previously recorded will be deleted,
        and may have a certain impact on the security of the services you use.
        For more information on how to change your browser Settings, you can
        visit the Settings page for the browser you are using.
      </div>
      <div style="margin-top: 20px; font-size: 20px">
        (Two) Cookie similar technology
      </div>
      <div style="margin-top: 20px">
        In addition to cookies, we may also use website beacons, pixel tags and
        other similar technologies. The email we send you may contain an address
        link to the content of our website, and if you click on the link, we
        will track the click to help us understand your product or service
        preferences so that we can proactively improve the customer service
        experience. A website beacon is usually a transparent image embedded in
        a website or email. With the help of the pixel tags in the email, we can
        tell if the email has been opened. If you do not want your activity to
        be tracked in this way, you can unsubscribe from our mailing list at any
        time.
      </div>
      <div style="margin-top: 20px; font-size: 20px">
        Three、How do we share, transfer and publicly disclose your personal
        information
      </div>
      <div style="margin-top: 20px">（One）Share</div>
      <div style="margin-top: 20px">
        1、We do not share your personal information with companies,
        organizations and individuals outside of Prefootball, except in the
        following cases:
      </div>
      <div style="margin-top: 20px">
        (1) when required by laws and regulations or mandatory administrative or
        judicial requirements;
      </div>
      <div style="margin-top: 20px">
        (2) Obtain your express consent or authorization in advance.
      </div>
      <div style="margin-top: 20px">（Two）Transfer</div>
      <div style="margin-top: 20px">
        We will not transfer your personal information to any company,
        organization or individual, except in the following cases:
      </div>
      <div style="margin-top: 20px">
        1. Transfer with express consent: After obtaining your express consent,
        we will transfer your personal information to other parties;
      </div>
      <div style="margin-top: 20px">
        2, in accordance with applicable laws and regulations, mandatory
        administrative or judicial requirements necessary to provide;
      </div>
      <div style="margin-top: 20px">
        3, in accordance with the relevant agreement signed with you (including
        online signed electronic agreement and the corresponding platform rules)
        or other legal documents provided;
      </div>
      <div style="margin-top: 20px">（Three）Public disclosure</div>
      <div style="margin-top: 20px">
        We will only publicly disclose your personal information in the
        following circumstances:
      </div>
      <div style="margin-top: 20px">
        1, with your explicit consent or based on your active choice, we may
        publicly disclose your personal information;
      </div>
      <div style="margin-top: 20px">
        2. If we determine that you have violated laws and regulations or
        seriously violated the relevant agreements and rules of Prefootball
        platform, or in order to protect the personal and property safety of
        other users or the public from infringement, we may disclose your
        personal information in accordance with laws and regulations or with
        your consent. Including related violations and actions that Prefootball
        has taken against you.
      </div>

      <div style="margin-top: 20px">
        3. We may publicly disclose the account information of the relevant
        illegal accounts when we make a penalty announcement for violations of
        Prefootball platform agreements and/or rules and/or violations of laws
        and regulations.
      </div>
      <div style="margin-top: 20px; font-size: 20px">
        Four、How do we protect and store your personal information
      </div>
      <div style="margin-top: 20px">
        (a) Our technology and measures to protect your personal information
      </div>
      <div style="margin-top: 20px">
        We have taken reasonable security measures in line with industry
        standards to protect your information, prevent unauthorized access to
        your personal information, use, modification, and prevent data damage or
        loss.
      </div>
      <div style="margin-top: 20px">
        Protected by SSL protocol encryption when exchanging data between your
        browser and the server; We provide browsing services through HTTPS to
        ensure the security of user data during transmission; We will use
        encryption technology to keep your personal information confidential,
        and isolation technology to isolate, when your personal information is
        used, we will use a variety of data desensitization technology,
        including content replacement, to increase the security of personal
        information in use. We use strict data access control and multi-identity
        authentication technology to protect your personal information from
        unauthorized use of data, and we hold security and privacy protection
        training courses to enhance employees' awareness of the importance of
        protecting personal information.
      </div>
      <div style="margin-top: 20px">
        We regulate the storage and use of personal information by establishing
        a data classification and grading system and data security management
        specifications, and we will take reasonable and feasible measures to
        ensure that no irrelevant personal information is collected. We will
        only retain your personal information to the extent described in this
        Privacy Policy, unless permitted by law or regulations or you agree that
        it is necessary to extend the retention. The Internet is not an
        absolutely secure environment, E-mail, social software, instant
        messaging communication tools can not be sure whether fully encrypted,
        we will do our best to ensure the security of the personal information
        you send to us, and we recommend that you use complex passwords and take
        care to protect your personal information. If you have any questions
        about the protection of our personal information, you can contact us
        through the contact details set forth in this Privacy Policy. If you
        find that your personal information has been compromised, especially
        your account and password, please contact us immediately through this
        privacy policy so that we can take appropriate measures.
      </div>
      <div style="margin-top: 20px">
        (2) The preservation of your personal information
      </div>
      <div style="margin-top: 20px">
        1, laws and regulations clearly stipulated; 2, obtain your explicit
        authorization; 3. You conduct cross-border transactions through the
        Internet on your own initiative. In view of the above circumstances, we
        will store and protect your personal information securely in accordance
        with the deserved death policy, and the storage period of your personal
        information data is limited to a reasonable period stipulated by laws
        and regulations. If we change the data storage time, we will notify you
        in advance. After the cancellation of the account, we will stop
        providing you with products and services, and according to your request,
        delete your personal information or anonymous processing, except as
        otherwise provided by laws and regulations.
      </div>
      <div style="margin-top: 20px">
        (3) Obtaining copies of personal information
      </div>
      <div style="margin-top: 20px">
        You can request a copy of your personal information on the Prefootball
        platform through online customer service or telephone customer service,
        which will be sent to your designated email address within 10 working
        days after your application.
      </div>
      <div style="margin-top: 20px; font-size: 20px">
        Five. How do you manage your Personal information
      </div>
      <div style="margin-top: 20px">
        We attach great importance to and protect your personal information, you
        can access and manage your information in the following ways:
      </div>
      <div style="margin-top: 20px">
        (a) Access and correct your information
      </div>
      <div style="margin-top: 20px">
        Unless otherwise provided by laws and regulations, you have the right to
        access and correct your personal information at any time. You can check
        your information by yourself in the following ways:
      </div>
      <div style="margin-top: 20px">
        (1) Your account information: You can access it by entering the
        "Personal Center" on the page of the "Prefootball" client
      </div>
      <div style="margin-top: 20px">(2) Cancel your account</div>
      <div style="margin-top: 20px">
        You can cancel your account by your own operation, specifically in the
        "Personal Center" to apply. We will close your account within 7 working
        days of your request.
      </div>
      <div style="margin-top: 20px">
        (3) Constraint information system automatic decision-making
      </div>
      <div style="margin-top: 20px">
        In some business functions, we may only make decisions based on
        non-human automatic decision mechanisms, including information systems
        and algorithms. If these decisions significantly affect your legitimate
        rights and interests, you have the right to ask us for an explanation
        and we provide appropriate remedies.
      </div>
      <div style="margin-top: 20px; font-size: 20px">
        Six、Information protection of minors
      </div>
      <div style="margin-top: 20px">
        Our products and/or services are primarily aimed at adults. Minors
        (especially children under 14 years of age) should not create their own
        accounts without the consent of a parent or guardian. When accepting the
        goods and/or services provided by us, we assume that you have the
        corresponding capacity for civil rights and civil conduct. If you are a
        minor, we ask that you ask your parents or guardians to read this
        Privacy Policy carefully and use our products and/or services or provide
        information to us with the consent of your parents or guardians.
      </div>
      <div style="margin-top: 20px">
        In cases where personal information of minors is collected with the
        consent of a parent or guardian to use our products and/or services, we
        will only use or disclose this information as permitted by law or
        regulation, with the express consent of the parent or guardian, or as
        necessary to protect the minor.
      </div>
      <div style="margin-top: 20px">
        Minors or guardians have the right to access, correct and delete
        children's personal information. When you are the guardian of a minor,
        if you have any comments, suggestions and demands related to the
        personal information of a minor, please contact us, we will provide
        services for you at any time. We will protect the confidentiality and
        security of minor user information in accordance with relevant national
        laws and regulations and the provisions of this policy. If we discover
        that we have collected personal information from a minor without the
        prior consent of a verifiable parent or legal guardian, we will try to
        delete the data as soon as possible. We will protect the confidentiality
        and security of minor user information in accordance with relevant
        national laws and regulations and the provisions of this privacy policy.
        If we discover that we have collected personal information from a minor
        without the prior consent of a verifiable parent or legal guardian, we
        will try to delete the data as soon as possible.
      </div>
      <div style="margin-top: 20px; font-size: 20px">
        Seven、Privacy Policy revisions and updates
      </div>
      <div style="margin-top: 20px">
        In order to provide you with better products and/or services, this
        Privacy Policy may be revised or updated, but we will not reduce or
        limit your rights under this Privacy Policy without your express
        consent. We will update the Privacy Policy by Posting an updated version
        on the Prefootball platform and reminding you of the updated Privacy
        Policy before the updated version becomes effective by a platform notice
        or other appropriate means, and you are also requested to visit the
        Prefootball Platform to stay up-to-date with the latest Privacy Policy.
      </div>
      <div style="margin-top: 20px">
        For significant changes, we will provide prominent notice (including
        special reminders via our Prefootball platform notifications, SMS), and
        again with your consent.
      </div>
      <div style="margin-top: 20px">
        Significant changes referred to in this Privacy Policy include:
      </div>
      <div style="margin-top: 20px">
        1, our service model has changed significantly, including the purpose of
        processing personal information, the type of personal information
        processed, and the way personal information is used;
      </div>
      <div style="margin-top: 20px">
        2. Significant changes in our control rights and organizational
        structure, including changes in information controllers caused by
        mergers and acquisitions, reorganizations, and bankruptcies;
      </div>
      <div style="margin-top: 20px">
        3, the main object of personal information sharing, transfer or public
        disclosure has changed;
      </div>
      <div style="margin-top: 20px">
        4, your rights to participate in the processing of personal information
        and the way they are exercised have changed significantly;
      </div>
      <div style="margin-top: 20px">
        5. Our responsible departments, contact methods and complaint channels
        for handling personal information security have changed;
      </div>
      <div style="margin-top: 20px">
        6. The personal information security impact assessment report indicates
        high risk.
      </div>
      <div style="margin-top: 20px; font-size: 20px">
        Eight、How to contact us
      </div>
      <div style="margin-top: 20px">
        If you have any questions, comments or suggestions about this Privacy
        Policy, you can contact us through the following ways, we will review
        the content of the matter as soon as possible, and reply within 15 days
        after verifying your user identity, you can also seek solutions through
        external channels, to the people's court where the defendant is located
        civil litigation:
      </div>
      <div style="margin-top: 20px">
        1、 send email to tiantiansun@sourceaz.com；
      </div>
      <div style="margin-top: 20px">2、phone：（+44）7400325263。</div>
      <div style="margin-top: 20px">
        3、Address：333 Spaces Victoria，25 Wilton Road，Victoria London（SW1V
        1LW）
      </div>
      <div style="height: 100px"></div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "privacy",
  components: {},
  data() {
    return {};
  },
  mounted() {
    document.title = "PrefootBall";
  },
};
</script>
  
  <style lang="scss" scoped>
.organ_page {
  width: 100%;
  padding-top: 40px;
  .marl40 {
    margin-left: 40px;
    margin-right: 40px;
  }
  .imgs {
    margin-top: 100px;
    @include flex(column, flex-start, center);
    img {
      width: 1000px;
      margin-bottom: 50px;
    }
  }
}
</style>
  